const PARAM_SLIM = 'slimnav';

function makeStyleSheet() {
    
    /**
     * @type {CSSStyleSheet}
     */
    const style = document.createElement('style');

    //style.appendChild(document.createTextNode(''));
    document.head.appendChild(style);
    const sheet = style.sheet;

    //sheet.insertRule('.uninav__hideaway { top: -82px; z-index: -1; transition: top 1s ease-in-out 0s; }');
    sheet.insertRule('.uninav__top-bar, .vip-message { position: relative; z-index: 103 }');
    sheet.insertRule('.uninav__top-bar .uninav__top-bar__content { max-width: 1290px }');
    sheet.insertRule('.uninav__top-bar .uninav__top-bar__credentials { min-width: 190px }');
    
    sheet.insertRule('.uninav__logo--hideaway { display: flex; align-items: center; height: 100%; width: 190px; margin-left: 20px !important; }');
    sheet.insertRule('.uninav__logo--hideaway a { color: transparent; width: 100%; background-repeat: no-repeat; background-size: contain;}');
    sheet.insertRule('.uninav--unrecognized .uninav__logo--hideaway { margin-left: 210px !important; }'); 

    sheet.insertRule('.uninav__hideaway--show { top: 0 !important; z-index: 1 !important; }');
    
    sheet.insertRule('.uninav-knob { width: 32px; height: 32px; display: flex; justify-content: center; align-items: center; transform-origin: center; transition: transform 0.25s linear; cursor: pointer; }');
    sheet.insertRule('.uninav-knob--open { transform: rotate(45deg); }');

}

/**
 * 
 * @param {Element} knob 
 * @param {URLSearchParams} params 
 */
function reloadToggle(knob, params) {
    
    params.set(PARAM_SLIM, parseInt(params.get(PARAM_SLIM)) ? 0 : 1);

    window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
}

/**
 * add the knob control.
 * @param {HTMLElement} inElement the parent element into which to append the knob
 */
function makeKnob(inElement) {
    if(!inElement) return null;

    const knob = document.createElement('div');

    knob.classList.add('uninav-knob');
    knob.innerHTML = '<img src="//pchassets.pch.com/sites/sw/lrvl/1.0.6/images/collapse-toggle.png" alt="reveal uninav">';

    inElement.appendChild(knob);

    return knob;
}

function init() {
    const knobContainer = document.querySelector('.uninav__top-bar__content');
    const menu = document.querySelector('.uninav__menu');
    
    const slimNavCfg = knobContainer.dataset.slimnav;
    const params = new URLSearchParams(window.location.search);

    if(!slimNavCfg && !params.has(PARAM_SLIM)) return;

    if(slimNavCfg) {
        params.set(PARAM_SLIM, 1);
    } else {
        params.set(PARAM_SLIM, 0);
    }

    if(knobContainer) {
        if(menu) menu.classList.add('uninav__hideaway');
        makeStyleSheet();
        const knob = makeKnob(knobContainer);
        
        knob.addEventListener('click', reloadToggle.bind(null, knob, params));
        if(parseInt(params.get(PARAM_SLIM))) {
            if(knob) knob.classList.remove('uninav-knob--open');
        } else {
            if(knob) knob.classList.add('uninav-knob--open');
        }
        
    }

}

document.addEventListener('DOMContentLoaded', init);